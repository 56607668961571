import { css, FlattenSimpleInterpolation } from 'styled-components'

import { Theme } from '@vfuk/core-themes/dist/themes.types'
import { spacingKeys } from '@vfuk/core-themes/dist/constants/spacing.types'

import { MixinResponsivePosition, properties, validSpacingKeys } from './responsivePosition.types'

import respondTo from '../respondTo'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin((theme: Theme, property: properties, value: validSpacingKeys): FlattenSimpleInterpolation => {
  const IsValueNegative = value > 0 ? ' ' : ' -'
  const positiveValue = Math.abs(value) as spacingKeys

  return css`
    ${property}:${IsValueNegative}${theme.spacing.responsive[positiveValue!].sm}px;

    ${respondTo.md(css`
      ${property}:${IsValueNegative}${theme.spacing.responsive[positiveValue!].md}px;
    `)}

    ${respondTo.lg(css`
      ${property}:${IsValueNegative}${theme.spacing.responsive[positiveValue!].lg}px;
    `)}

      ${respondTo.xl(css`
      ${property}:${IsValueNegative}${theme.spacing.responsive[positiveValue!].xl}px;
    `)}
  `
}) as MixinResponsivePosition
