import { css, FlattenSimpleInterpolation } from 'styled-components'

import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { MixinElevation } from './elevation.types'

import createMixin from '../utils/createMixin'

export default createMixin((theme: Theme, elevation: boolean = false): FlattenSimpleInterpolation => {
  const boxShadow = `${theme.elevation.size}px ${theme.elevation.spread}px ${theme.elevation.offset}px ${theme.elevation.color}`

  return css`
    box-shadow: ${elevation ? boxShadow : 'none'};
  `
}) as MixinElevation
