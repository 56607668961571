import { css, FlattenSimpleInterpolation } from 'styled-components'

import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { MixinOpacity } from './opacity.types'

import createMixin from '../utils/createMixin'

export default createMixin(
  (theme: Theme, disabled: boolean = false): FlattenSimpleInterpolation => css`
    opacity: ${disabled ? theme.opacity : '1'};
  `,
) as MixinOpacity
