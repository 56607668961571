import { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacingKeys } from '@vfuk/core-themes/dist/constants/spacing.types'
import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { MixinAdvancedSpacing, AdvancedSpacingValues, properties } from './advancedSpacing.types'

import respondTo from '../respondTo'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin(
  (theme: Theme, property: properties, values: AdvancedSpacingValues): FlattenSimpleInterpolation => css`
    ${property}: ${values.map((value) => {
      if (value === 'auto') {
        return `${value} `
      }
      if (value && value < 0) {
        return `-${theme.spacing.responsive[Math.abs(value) as spacingKeys].sm}px `
      }
      return `${theme.spacing.responsive[value as spacingKeys].sm}px `
    })};

    ${respondTo.md(css`
      ${property}: ${values.map((value) => {
        if (value === 'auto') {
          return `${value} `
        }
        if (value && value < 0) {
          return `-${theme.spacing.responsive[Math.abs(value) as spacingKeys].md}px `
        }
        return `${theme.spacing.responsive[value as spacingKeys].md}px `
      })};
    `)}

    ${respondTo.lg(css`
      ${property}: ${values.map((value) => {
        if (value === 'auto') {
          return `${value} `
        }
        if (value && value < 0) {
          return `-${theme.spacing.responsive[Math.abs(value) as spacingKeys].lg}px `
        }
        return `${theme.spacing.responsive[value as spacingKeys].lg}px `
      })};
    `)}

    ${respondTo.xl(css`
      ${property}: ${values.map((value) => {
        if (value === 'auto') {
          return `${value} `
        }
        if (value && value < 0) {
          return `-${theme.spacing.responsive[Math.abs(value) as spacingKeys].xl}px `
        }
        return `${theme.spacing.responsive[value as spacingKeys].xl}px `
      })};
    `)}
  `,
) as MixinAdvancedSpacing
