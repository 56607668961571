import { css, FlattenSimpleInterpolation } from 'styled-components'

import { Theme } from '@vfuk/core-themes/dist/themes.types'
import { spacingKeys } from '@vfuk/core-themes/dist/constants/spacing.types'

import { MixinSpacing, properties } from './spacing.types'

import respondTo from '../respondTo'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin(
  (theme: Theme, property: properties, value: spacingKeys): FlattenSimpleInterpolation => css`
    ${value < 0 &&
    css`
      ${property}: -${theme.spacing.responsive[Math.abs(value) as spacingKeys].sm}px;

      ${respondTo.md(css`
        ${property}: -${theme.spacing.responsive[Math.abs(value) as spacingKeys].md}px;
      `)}

      ${respondTo.lg(css`
        ${property}: -${theme.spacing.responsive[Math.abs(value) as spacingKeys].lg}px;
      `)}

    ${respondTo.xl(css`
        ${property}: -${theme.spacing.responsive[Math.abs(value) as spacingKeys].xl}px;
      `)}
    `}

    ${value > -1 &&
    css`
      ${property}: ${theme.spacing.responsive[value].sm}px;

      ${respondTo.md(css`
        ${property}: ${theme.spacing.responsive[value].md}px;
      `)}

      ${respondTo.lg(css`
        ${property}: ${theme.spacing.responsive[value].lg}px;
      `)}

    ${respondTo.xl(css`
        ${property}: ${theme.spacing.responsive[value].xl}px;
      `)}
    `}
  `,
) as MixinSpacing
